import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { FormattedMessage } from "gatsby-plugin-intl"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Hero from "../../components/hero"
import Demo from "../../components/demo"
import ClientCarousel from "../../components/clientCarousel"

import SegmentsPersonalization from "../../images/icons/segments-personalization.svg";
import PersonalizedCampaigns from "../../images/icons/personalized-campaigns.svg";
import DynamicContent from "../../images/icons/dynamic-content.svg";
import ABTests from "../../images/icons/ab-tests.svg";
import RecommendedProducts from "../../images/icons/recommended-products.svg";
import Reporting from "../../images/icons/reporting.svg";

import BrowseProducts from "../../images/icons/browse-products.svg";
import PriceNotifications from "../../images/icons/price-notifications.svg";
import BrowseCarts from "../../images/icons/browse-carts.svg";

class SuccessStories extends Component {
  reveal () {
    const ScrollReveal = require('scrollreveal').default

    ScrollReveal({
      viewFactor: 0.4,
      distance: '100px',
      duration: 1000
    });

    ScrollReveal().reveal('.sr-1', {
      delay: 0
    });

    ScrollReveal().reveal('.sr-2', {
      delay: 100
    });

    ScrollReveal().reveal('.sr-3', {
      delay: 200
    });

    ScrollReveal().reveal('.sr-r', {
      origin: 'right'
    });

    ScrollReveal().reveal('.sr-b', {
      origin: 'bottom'
    });

    ScrollReveal().reveal('.sr-q', {
      delay: 100,
      beforeReveal: function () {
        document.getElementsByClassName('sr-q')[0].classList.add('is-loaded');
      }
    });
  }

  componentDidMount () {
    if (typeof window !== 'undefined') {
      this.reveal();
    }
  }

  render () {
    return (
      <Layout>
        <SEO title="电商成功案例 - ExpertSender" description="了解我们的客户如何使用我们的带货神器来助推电商业绩." />
        <Hero title="电商成功案例" subtitle="了解我们的客户如何使用我们的带货神器来助推电商业绩." />

        <ClientCarousel />

        <div className="section-gr pad-t-3 pad-b-3 mar-t-3 mar-b-6">
          <div className="side side--both side--r-l side--gray" />
          <div className="container container--pad pad-t-6 pad-b-6">
            <div className="section-h">
              <h2 className="section-h__2 mar-t-3 mar-b-3">
                <FormattedMessage id="sell_more.h_ss" />
              </h2>
            </div>
   
            <div className="section-ss columns pad-t-1">
              <div className="column sr-hidden sr-1">
                <div className="card-ss card-ss--cover card-ss--full card-ss--shadow card-ss--pad">
                  <div className="card-ss__img">
                    <Img fluid={this.props.data.mkfreshCover.childImageSharp.fluid} className="card-ss__cover" />
                    <Img fixed={this.props.data.mkfreshLogo.childImageSharp.fixed} className="card-ss__coverlogo" />
                  </div>
                  <h3 className="card-ss__h card-ss__h--bigger">
                    <FormattedMessage id="sell_more.ss_1" />
                  </h3>
                  <div className="columns mar-t-2">
                    <div className="card-ss__stat column is-narrow">
                      <span className="is-block has-text-weight-bold has-text-secondary">+67%</span>
                      OV
                    </div>
                    <div className="card-ss__stat column is-narrow">
                      <span className="is-block has-text-weight-bold has-text-secondary">+34%</span>
                      CTR
                    </div>
                    <div className="card-ss__stat column is-narrow">
                      <span className="is-block has-text-weight-bold has-text-secondary">+33%</span>
                      CVR
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad pad-t-3 mar-t-6 mar-b-6">
          <div className="columns is-variable is-8 is-vcentered">
            <div className="column sr-hidden sr-1">
              <div className="section-h section-h--full">
                <h2 className="section-h__2 mar-b-2">
                  <FormattedMessage id="ecommerce.h_1" />
                </h2>
                <p className="section-h__3">
                  <FormattedMessage id="ecommerce.t_1" />
                </p>
              </div>
            </div>
            <div className="column">
              <div className="columns mar-b-2 sr-hidden sr-1">
                <div className="column is-one-fifth">
                  <BrowseProducts className="section-i__ico" />
                </div>
                <div className="column">
                  <h3 className="section-h__3 has-text-weight-bold mar-b-1">
                    <FormattedMessage id="ecommerce.f_h_1" />
                  </h3>
                  <p>
                    <FormattedMessage id="ecommerce.f_t_1" />
                  </p>
                </div>
              </div>
              <div className="columns mar-b-2 sr-hidden sr-2">
                <div className="column is-one-fifth">
                  <PriceNotifications className="section-i__ico" />
                </div>
                <div className="column">
                  <h3 className="section-h__3 has-text-weight-bold mar-b-1">
                    <FormattedMessage id="ecommerce.f_h_2" />
                  </h3>
                  <p>
                    <FormattedMessage id="ecommerce.f_t_2" />
                  </p>
                </div>
              </div>
              <div className="columns sr-hidden sr-3">
                <div className="column is-one-fifth">
                  <BrowseCarts className="section-i__ico" />
                </div>
                <div className="column">
                  <h3 className="section-h__3 has-text-weight-bold mar-b-1">
                    <FormattedMessage id="ecommerce.f_h_3" />
                  </h3>
                  <p>
                    <FormattedMessage id="ecommerce.f_t_3" />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="section-ss is-relative columns mar-t-3 pad-t-6">
            <div className="parallax parallax--circle" style={{ top: `50px`, left: `-90px`, transform: 'rotate(-90deg)' }} />
            <div className="column sr-hidden sr-1">
              <div className="card-ss card-ss--right card-ss--cover card-ss--full card-ss--shadow card-ss--pad">
                <div className="card-ss__img">
                  <Img fluid={this.props.data.forFCover.childImageSharp.fluid} className="card-ss__cover" />
                  <Img fixed={this.props.data.forFLogo.childImageSharp.fixed} className="card-ss__coverlogo" />
                </div>
                <h3 className="card-ss__h card-ss__h--bigger">
                  <FormattedMessage id="ecommerce.ss_2" />
                </h3>
                <div className="columns mar-t-2">
                  <div className="card-ss__stat column is-narrow">
                    <span className="is-block has-text-weight-bold has-text-secondary">+2.5x</span>
                    UCR
                  </div>
                  <div className="card-ss__stat column is-narrow">
                    <span className="is-block has-text-weight-bold has-text-secondary">+2.8x</span>
                    OR
                  </div>
                  <div className="card-ss__stat column is-narrow">
                    <span className="is-block has-text-weight-bold has-text-secondary">12%</span>
                    ROI
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad pad-t-2 pad-b-1 mar-t-6 mar-b-6">
          <div className="columns is-variable is-6 mar-b-2">
            <div className="column sr-hidden sr-1">
              <SegmentsPersonalization className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="ecommerce.f_h_4" />
              </h3>
              <p>
                <FormattedMessage id="ecommerce.f_t_4" />
              </p>
            </div>
            <div className="column sr-hidden sr-2">
              <PersonalizedCampaigns className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="ecommerce.f_h_5" />
              </h3>
              <p>
                <FormattedMessage id="ecommerce.f_t_5" />
              </p>
            </div>
            <div className="column sr-hidden sr-3">
              <DynamicContent className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="ecommerce.f_h_6" />
              </h3>
              <p>
                <FormattedMessage id="ecommerce.f_t_6" />
              </p>
            </div>
          </div>
        </div>

        <div className="container container--pad">
          <div className="section-ss is-relative columns">
            <div className="parallax" style={{ top: `auto`, bottom: '-100px', right: `-90px`, transform: 'rotate(45deg)' }} />
            <div className="column sr-hidden sr-1">
              <div className="card-ss card-ss--cover card-ss--full card-ss--shadow card-ss--pad">
                <div className="card-ss__img">
                  <Img fluid={this.props.data.veneziaCover.childImageSharp.fluid} className="card-ss__cover" />
                  <Img fixed={this.props.data.veneziaLogo.childImageSharp.fixed} className="card-ss__coverlogo" />
                </div>
                <h3 className="card-ss__h card-ss__h--bigger">
                  <FormattedMessage id="ecommerce.ss_3" />
                </h3>
                <div className="columns mar-t-2">
                  <div className="card-ss__stat column is-narrow">
                    <span className="is-block has-text-weight-bold has-text-secondary">+12%</span>
                    CR
                  </div>
                  <div className="card-ss__stat column is-narrow">
                    <span className="is-block has-text-weight-bold has-text-secondary">16x</span>
                    ROI
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container container--pad pad-t-3 pad-b-1 mar-t-6 mar-b-6">
          <div className="columns is-variable is-6 mar-b-2">
            <div className="column sr-hidden sr-1">
              <ABTests className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="ecommerce.f_h_7" />
              </h3>
              <p>
                <FormattedMessage id="ecommerce.f_t_7" />
              </p>
            </div>
            <div className="column sr-hidden sr-2">
              <RecommendedProducts className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="ecommerce.f_h_8" />
              </h3>
              <p>
                <FormattedMessage id="ecommerce.f_t_8" />
              </p>
            </div>
            <div className="column sr-hidden sr-3">
              <Reporting className="section-i__ico" />
              <h3 className="section-h__3 has-text-weight-bold mar-t-2 mar-b-1">
                <FormattedMessage id="ecommerce.f_h_9" />
              </h3>
              <p>
                <FormattedMessage id="ecommerce.f_t_9" />
              </p>
            </div>
          </div>
        </div>

        <Demo props={this.props.data} />
      </Layout>
    )
  }
}

export default SuccessStories

export const pageQuery = graphql`
  query {
    mkfreshCover: file(relativePath: { eq: "success-stories/mkfresh-cover-full.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 821, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    mkfreshLogo: file(relativePath: { eq: "success-stories/mkfresh-logo.png" }) {
      childImageSharp {
        fixed(height: 80, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    forFCover: file(relativePath: { eq: "success-stories/4f-cover-full.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 821, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    forFLogo: file(relativePath: { eq: "success-stories/4f-logo.png" }) {
      childImageSharp {
        fixed(height: 80, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    veneziaCover: file(relativePath: { eq: "success-stories/venezia-cover-full.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 821, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    veneziaLogo: file(relativePath: { eq: "success-stories/venezia-logo.png" }) {
      childImageSharp {
        fixed(height: 80, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    szopexCover: file(relativePath: { eq: "success-stories/szopex-cover-full.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 821, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    szopexLogo: file(relativePath: { eq: "success-stories/szopex-logo.png" }) {
      childImageSharp {
        fixed(height: 110, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    esDashboard: file(relativePath: { eq: "expertsender-dashboard.png" }) {
      childImageSharp {
        fluid(maxWidth: 1260, quality: 80) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
